import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import Teams from "../components/Teams"
import Intro from "../components/Intro"
import SEO from "../components/SEO"

const TeamPage = ({
  data: {
    allContentfulTeam: { nodes: team },
    contentfulIntroTeam
  },
}) => {
  const teamIntro = contentfulIntroTeam;
  return (
  <Layout>
    <SEO title="Team" description="Meet and learn about the High Rock Capital team."/>
    <div className="team-page">
      <div className="section-center-narrow">
        <Intro data={teamIntro} slide/>
      </div>
      <div>
      <div className="section-color-gray team-list">
        <section className="section-center-narrow">
          <Teams team={team} title="Meet your team" list deepLink linkTo/>
        </section>
      </div>
      </div>
    </div>
  </Layout>
  );
}

export const query = graphql`
{
    allContentfulTeam(sort: {fields: id, order: ASC}) {
        nodes {
        id
        name
        position
        teaser {
            teaser
        }
        slug
        image {
            fluid {
                src
            }
        }
    }
  }
  contentfulIntroTeam {
    id
    subTitle
    description {
      description
    }
    title
  }
}
`

export default TeamPage
